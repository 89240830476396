import { RootState } from '../index';

export const getStocktakeMode = (state: RootState) => ({
  mode: state.stocktakeBarcode.mode,
});

export const getStocktakeBarcode = (state: RootState) => ({
  barcode: state.stocktakeBarcode.barcode,
});

export const getStocktakeProducts = (state: RootState) => ({
  products: state.stocktakeBarcode.products,
  product: state.stocktakeBarcode.product,
  onlyOneProduct: state.stocktakeBarcode.onlyOneProduct,
});

export const getStockCount = (state: RootState) => ({
  stockCount: state.stocktakeBarcode.stockCount,
});

export const getStockSupplierSettings = (state: RootState) => ({
  supplierId: state.stocktakeBarcode.supplierId,
  isDefault: state.stocktakeBarcode.isDefault,
  isTransfer: state.stocktakeBarcode.isTransfer,
});

export const getStocktakeSettings = (state: RootState) => ({
  useSameOption: state.stocktakeBarcode.useSameOption,
});

export const getHasDefaultProduct = (state: RootState) => ({
  hasDefaultProduct: state.stocktakeBarcode.hasDefaultProduct,
  product: state.stocktakeBarcode.product,
});