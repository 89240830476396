import { PaginationResult, SuccessResponse } from '../index';
import { ExportedProduct, SortDir } from '../product/types';

export enum SetupInventoryStatus {
  confirmed = 'confirmed',
  incomplete = 'incomplete',
}

export interface Inventory {
  id: number;
  product_name: string;
  brand: string | null;
  code: null | string;
  setup_status: SetupInventoryStatus;
  favourite: boolean;
  product_price: string;
  stock_level: null | string;
  unit: null | string;
  balance: null | number;
  par: null | number;
  quantity: null | number;
  trigger: null | number;
  stock_variance: number;
  open_balance: number | null;
  close_balance: number | null;
  sub_measurement: string | null;
  sub_measurement_value: number | null;
  inventory_storage: {
    id: number;
    name: string;
  };
  inventory_shelf: {
    id: number;
    name: string;
  };
  picture_url: null | string;
  sub_category: null | string;
  place: null | string;
  product_id: number;
  unit_size: string | null;
  supplier: {
    id: number;
    name: string;
    picture_url: null | string;
    supplier_id: number;
    supplier_name: string;
  };
  is_default: boolean;
  is_transfer: boolean;
}

export interface GetInventoriesResponse extends PaginationResult {
  inventory_products: Inventory[];
}

export interface GetRunOutProductsResponse extends SuccessResponse {
  items: Inventory[];
  item_count: number;
}

export enum StocktakeMode {
  regularStocktake = 'regular',
  scanBarcode = 'barcode',
}

export type InventorySortField = 'product_name' | 'code' | 'location';

export interface InventoryFilter {
  favourite?: boolean;
  recently?: boolean;
  status?: InventorySetupStatus;
}

export type InventorySortBy = [InventorySortField, SortDir];

export interface GetInventoryListReqParams {
  keyword?: string;
  sort?: InventorySortBy;
  location?: string;
  favourite?: boolean;
  recently?: boolean;
  status?: InventorySetupStatus;
  supplierId?: number | null;
  page: number;
}

export interface InventoryListQueryParams {
  'q[keyword]'?: string;
  'q[sort_by]'?: string;
  'q[shelf_name]'?: string;
  'q[favourite]'?: boolean;
  recently_purchased?: boolean;
  'q[setup_status]'?: 1 | 0;
  supplier_id?: number;
}

export enum InventorySetupStatus {
  Incomplete = 0,
  Confirmed = 1,
}

export interface GetInventoryStorages extends SuccessResponse {
  storages: InventoryStorage[];
}

export interface InventoryStorage {
  id: number;
  name: string;
  inventory_shelves: {
    id: number;
    name: string;
    position: number | null;
  }[];
  inventory_products: Inventory[];
}

export interface SetUpInventoryReq {
  id: number;
  shelf_id?: number | null;
  storage_id?: number | null;
  inventory_product: {
    balance?: number;
    par?: number;
    trigger?: number;
    place?: string | null;
  };
}

export interface SetUpInventoryResponse extends SuccessResponse {
  inventory_product: Inventory;
}

export interface CreateShelfResponse extends SuccessResponse {
  storage: InventoryStorage;
}

export interface MarkFavouriteRes extends SuccessResponse {
  product: Inventory;
}

export interface InventoryStoragesReq {
  recently_purchased?: boolean;
  name?: string;
}

export interface ExportInventoryRes extends SuccessResponse {
  data: any[];
  headers: { label: string; key: string }[];
}
